import React from "react";
import { ActionType, AppStateType } from "./types";

export interface AppContextType {
  state: AppStateType;
  dispatch: (action: ActionType) => void;
}

const AppContext = React.createContext({} as AppContextType);
export default AppContext;
